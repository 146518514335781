import React from 'react'
import Timer from "../sounds/countdown.wav";
import Start from "../sounds/start-13691.mp3";
import Shutter from '../sounds/camera-shutter-click-08.mp3';

const Audio = (props) => {
    return (
        <>
            <audio ref={props.audioRef} src={Shutter} />
            <audio ref={props.timerRef} src={Timer} />
            <audio ref={props.startRef} src={Start} />
        </>
    )
}

export default Audio