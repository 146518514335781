import React from 'react'
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import * as Ons from 'react-onsenui';

const FormButtons = (props) => {
    return (
        <div className='button-wrapper'>
            <button className='button left' onClick={props.frame ? props.handleClose : props.backToPhoto}>
                <Ons.Icon icon='fa-chevron-left' style={{ color: '#000', fontSize: "1.5rem" }} /> BACK
            </button>
            <button className='button right' onClick={props.getDataUrlSketch}>
                SAVE CHANGES <Ons.Icon icon='fa-save' style={{ color: '#FEF4DD', fontSize: "1.5rem"}} />
            </button>
        </div>
    )
}

export default FormButtons