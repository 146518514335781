
import TakeSelfie from './components/TakeSelfie';

function App() {
  return (
    <TakeSelfie />

  );
}

export default App;
