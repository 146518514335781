import React from 'react'
import Logo from '../images/logo.png';
import Frame from '../images/newwow.png';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import * as Ons from 'react-onsenui';

const PhotoPage = (props) => {
    return (
        <div className={'result ' + (props.hasPhoto ? 'hasPhoto' : '')}>
            <div style={{ width: '25rem' }} ><img src={Logo} alt='hwa chong logo' className='logo-img' /></div>
            <div className='image-wrapper'>
                <canvas ref={props.photoRef} id="photo" className='image' />
            </div>

            {/* {props.base64Image &&
                <div className='image-wrapper'>
                    <img ref={props.imageRef} src={props.base64Image} alt="selfie" className='image' />
                </div>
            } */}
            <img ref={props.overlayRef} src={Frame} style={{ display: "none" }} alt="frame" />
            <div className='button-wrapper'>
                <button className='button left' onClick={props.closePhoto}>
                    <Ons.Icon icon='fa-rotate-right' style={{ color: '#000', fontSize: "1.5rem" }} /> RETAKE
                </button>
                <button className='button right' onClick={props.startDrawing}>
                    ADD MESSAGES <Ons.Icon icon='fa-pen' style={{ color: '#FEF4DD', fontSize: "1.5rem" }} />
                </button>
            </div>
        </div>
    )
}

export default PhotoPage