import React, { useEffect, useRef } from 'react';
import Logo2 from "../images/logo-2.png";
import MainImage from "../images/image.png";
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import * as Ons from 'react-onsenui';

const MainPage = (props) => {
    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);


    return (
        <div className='camera'>
            <img src={Logo2} alt="hwa chong logo" className='main-logo' />
            <h1 className='main-text'>Welcome to <br /><span>Hwa Chong Heritage Gallery</span></h1>
            <div className="camera-border"><img className='close-img' src={MainImage} alt="illustration" /></div>
            <h6 className='main-text-2'>Commemorate your visit by leaving your messages and well wishes</h6>
            {props.isVisible && (
                <div ref={ref} id="myDiv" className="animated-div">
                    <h2>Commemorate your visit :</h2>
                    <div className='options'>
                        <button className="option" style={{ marginRight: "3rem" }} onClick={props.startApp} >
                            <Ons.Icon icon='fa-camera' style={{ color: '#6e1919', fontSize: "3rem", marginTop: "2rem" }} />
                            <h1 className='option-text'>Selfie & Message</h1>
                        </button>
                        <button className="option" onClick={props.startDraw}>
                            <Ons.Icon icon='fa-pen' style={{ color: '#6e1919', fontSize: "3rem", marginTop: "2rem" }} />
                            <h1 className='option-text'>Message Only</h1>
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MainPage