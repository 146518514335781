import React from 'react'
import { ReactSketchCanvas } from 'react-sketch-canvas';
import Frame from '../images/newwow.png';


const CanvasDrawComponent = (props) => {
    const canvasWidth = window.innerWidth;
    console.log(canvasWidth)
    const canvasHeight = canvasWidth * (9 / 16);
    console.log(canvasHeight)

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: "100%", overflow: 'hidden' }}>
            <ReactSketchCanvas
                ref={props.canvasSketchRef}
                id='react-sketch-canvas'
                width={canvasWidth}
                height={canvasHeight}
                strokeWidth={6}
                strokeColor={props.color}
                backgroundImage={props.frame ? Frame : props.base64Image}
                allowOnlyPointerType='all'
                eraserWidth={25}
                preserveBackgroundImageAspectRatio="xMidYMid meet"
                style={{
                    aspectRatio: `auto ${canvasWidth / canvasHeight}`
                }}
                exportWithBackgroundImage={props.frame ? true : false}
            />
        </div>
    )
}


export default CanvasDrawComponent