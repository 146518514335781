import React from 'react'
import Logo from '../images/logo.png';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import * as Ons from 'react-onsenui';
import Image from '../images/image.png';

const PrintPage = (props) => {
    return (
        <div id='photo-container'>
            <div style={{ width: '25rem' }}><img src={Logo} alt='hwa chong logo' className='logo-img' /></div>

            {/* {props.isSubmitted
                ? */}
            <div className='close-screen'>
                <h2 className='close-text'>Thank you for leaving <br /><span>your messages and well wishes</span></h2>
                <img className='close-img' src={Image} alt="illustration" />
            </div>
            <button className='main-start-button' onClick={props.handleClose} style={{ width: "100%" }}>
                START OVER <Ons.Icon icon='fa-rotate-right' style={{ color: '#FEF4DD', fontSize: "1.5rem", marginLeft: "2rem" }} />
            </button>
            {/* : */}
            <>
                {/* <div className='image-wrapper'>
                        <img src={props.data} alt="selfie" className='image' />
                    </div> */}
                {/* <div className='input-wrapper'>
                        <h6>Email photo to me</h6>
                        <div className='email-wrapper'>
                            <input type="email" placeholder='Enter your Email' className="input email" value={props.email} onChange={(e) => props.setEmail(e.target.value)} />
                            <button className='email-button' onClick={props.onSubmit}><Ons.Icon icon='fa-envelope' style={{ color: '#6e1919', fontSize: "1.5rem" }} /></button>
                        </div>
                    </div> */}
            </>
            {/* } */}
            {/* {props.printButton ? <div className={'print-wrapper ' + (props.isPrinted ? 'justifyCenter' : '')}>
                {props.isPrinted ?
                    <button className='main-start-button' onClick={props.handleClose} style={{ width: "100%" }}>
                        START OVER <Ons.Icon icon='fa-rotate-right' style={{ color: '#FEF4DD', fontSize: "1.5rem", marginLeft: "2rem" }} />
                    </button> :
                    <>
                        {props.isSubmitted ? <button className='button left' onClick={props.handleClose}>
                            <Ons.Icon icon='fa-rotate-right' style={{ color: '#6e1919', fontSize: "1.5rem" }} /> START OVER
                        </button> : <button className='button left' onClick={props.backToForm}>
                            <Ons.Icon icon='fa-chevron-left' style={{ color: '#000', fontSize: "1.5rem" }} /> BACK
                        </button>}
                        <button className='button right' onClick={props.handlePrint}>
                            PRINT <Ons.Icon icon='fa-print' style={{ color: '#FEF4DD', fontSize: "1.5rem" }} />
                        </button>
                    </>}
            </div>
                : <div className={'print-wrapper ' + (props.isPrinted ? 'justifyCenter' : '')}>
                    {props.isPrinted ?
                        <button className='main-start-button' onClick={props.handleClose} style={{ width: "100%" }}>
                            START OVER <Ons.Icon icon='fa-rotate-right' style={{ color: '#FEF4DD', fontSize: "1.5rem" }} />
                        </button> :
                        <>
                            {props.isSubmitted ? <button className='button left' onClick={props.handleClose}>
                                <Ons.Icon icon='fa-rotate-right' style={{ color: '#6e1919', fontSize: "1.5rem" }} /> START OVER
                            </button> : <button className='button left fullwidth' onClick={props.backToForm} style={{ justifyContent: 'center' }}>
                                <Ons.Icon icon='fa-chevron-left' style={{ color: '#000', fontSize: "1.5rem", marginRight: "2.5rem" }} /> BACK
                            </button>}
                        </>}
                </div>
            } */}
        </div>
    )
}

export default PrintPage