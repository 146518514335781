import React from 'react'
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import * as Ons from 'react-onsenui';

const SubmitPhoto = (props) => {
    return (
        <>
            {props.data &&
                <div className='image-wrapper'>
                    <img src={props.data} alt="selfie" className='image' />
                </div>
            }
            <form className='input-wrapper'>
                {/* <h6>Leave your well wishes here</h6> */}
                <input type="text" placeholder='Enter your Name (optional)' value={props.name} onChange={(e) => props.setName(e.target.value)} />
                {/* <input type="text" placeholder='Enter your well wishes' value={props.wellWishes} onChange={(e) => props.setWellWishes(e.target.value)} /> */}
                <input type="email" placeholder='Enter your Email (optional)' value={props.email} onChange={(e) => props.setEmail(e.target.value)} />
            </form>
            <div className='form-wrapper'>
                {/* <div className='checkbox-wrapper'>
                    <input type="checkbox" id="check" onChange={e => props.setShowPopup(e.target.checked)} />
                    <label htmlFor="check">I Agree To Terms & Conditions</label>
                </div> */}

                {props.showPopup &&
                    <div className="popup">
                        <div className="popup-content">
                            <div className='popup-header'>
                                <h2> SERVICE TERMS AND CONDITIONS OF USE</h2>
                                <div className='button-close-popup' onClick={props.handleClosePopup}>X</div>
                            </div>

                            <div className='content-popup'>
                                <p>
                                    The Service is owned and operated by HWACHONG INSTITUTION. The following Conditions govern your use and/or access of the Service. By using or accessing the Service, you are deemed to have accepted and agreed to be bound by these Conditions. It is your responsibility to ensure that you have read and understood these Conditions and any accompanying risks, obligations and responsibilities.
                                </p>
                                <strong>
                                    Please read these terms of use carefully before you start to use and/or access the Service. If you do not agree to any part of these Conditions, please do not use and/or access the Service.
                                </strong>
                                <p>
                                    <b>1. DEFINITIONS AND INTERPRETATION</b><br />
                                    <b>1.1	Definitions</b><br />
                                    <b>"<u>Conditions</u>"</b>	:	The terms and conditions contained herein and any amendments from time to time, as published on the Service.<br />
                                    <b>"<u>Personal Data</u>"</b>	:	Data, whether true or not, that can be used to identify, contact or locate you. Personal Data can include your name and email address<br />
                                    <b>"<u>HCI</u>", "<u>we</u>", "<u>our</u>" and "<u>us</u>"</b>	:	Hwachong Institution<br />
                                    <b>"<u>Service</u>"</b>	:	The application located on this kiosk/tablet PC.<br />
                                    <b>"<u>you</u>" or "<u>your</u>"</b>	:	Individuals who are 18 years of age or older, or otherwise under the supervision of a parent or legal guardian.<br />

                                </p>
                                <p>
                                    <b>1.2	Interpretation</b><br />
                                    (a)	The headings in these Conditions are inserted for convenience only and shall not affect the interpretation of these Conditions.<br />
                                    (b)	Unless the context otherwise requires, words importing the singular shall include the plural and vice versa and words importing a specific gender shall include the other genders (male, female or neuter).<br />
                                    (c)	Any reference to conduct includes without limitation, an omission, statement or undertaking, whether or not in writing.<br />
                                </p>
                                <strong>
                                    2. ACCESS AND USE OF THE SERVICE
                                </strong>
                                <p>
                                    <b>2.1	Terms of Use.</b><br />
                                    By using and/or accessing the Service, you hereby agree that:<br />
                                    (a)	<b>if you are below 18 years old</b>: you must obtain consent from your parent(s) or legal guardian(s), their acceptance of these Conditions and their agreement to take responsibility for: (i) your actions; (ii) your acceptance and compliance with these Conditions. <b>If you do not have consent from your parent(s) or legal guardian(s), you must stop using/accessing the Service;</b><br />
                                    (b)	you will not copy or distribute any part of the Service in any medium without our prior written authorisation; and<br />
                                    (c)	you will not use the Service for any purpose that is unlawful or prohibited by these Conditions, or to solicit the performance of any illegal activity or other activity which infringes our rights or the rights of others. Notwithstanding any other rights or restrictions in these Conditions, you may not use the Service to: (i) transmit via or through the Service any information, data, text, images, files, links or software except in connection with your authorised use of this Service or otherwise in response to specific requests for information by us, (ii) introduce to the Service or any other computer or website viruses, worms, Trojan horses and/or harmful code, (iii) obtain unauthorised access to any computer system, (iv) impersonate any other person or falsely state or otherwise misrepresent your affiliation with any person or entity, (v) invade the privacy or violate any personal or proprietary right (including intellectual property rights) of any person or entity, (vi) misrepresent the identity of a user or use a false e-mail address, (vii) tamper with or obtain access to the Service or any component of the Service, (viii) conduct fraudulent activities, or (ix) collect or harvest information regarding other users of the Service for any reason whatsoever, including, without limitation, for sending such users unsolicited commercial e-mail.<br />
                                </p>
                                <strong>3. CHANGES TO THE SERVICE</strong>
                                <p>
                                    We may alter, suspend, or discontinue this Service in whole or in part, at any time and for any reason, without notice or cost. We may, in our sole discretion, terminate or suspend your use of or access to all or part of the Service for any reason, including without limitation, breach of these Conditions. Any of the material on the Service may be out of date at any given time, and we are under no obligation to update such material.
                                </p>
                                <strong>4. LIMITATION OF LIABILITY</strong>
                                <p>
                                    <b>4.1 No Representations or Warranties.</b><br />
                                    The Service, its content, services and all text, images, merchandise and other information provided herein are provided on an "as is" and "as available" basis without representations or warranties of any kind, whether express, implied or statutory, including but not limited to warranties of title or implied warranties of merchantability, satisfactory quality, fitness for a particular purpose or non-infringement.<br />
                                    Without limitation to the generality of the foregoing, we expressly disclaim any warranty, condition, guarantee, term or representation (a) as to the reliability, accuracy, completeness, and validity of any content or material on the Service, (b) that the functions contained on the Service will be secure, uninterrupted or free of errors, (c) that any defects will be corrected, or (d) that the Service or the server(s) that makes it available are free of viruses or other harmful components. Any and all such warranties, conditions, terms and representations are specifically excluded. We assume no responsibility for errors or omissions in the materials on the Service, including factual or other inaccuracies or typographical errors. You expressly agree that your use and/or access of the Service is at your sole risk.<br />
                                </p>
                                <p>
                                    <b>4.2 No Liability for Indirect or Consequential Losses.</b><br />
                                    To the maximum extent permitted under applicable law, we shall not be liable to any user of the Service or any other person for any direct, indirect, special or consequential loss or damage (including, but not limited to, damages for loss of profits, loss of data or loss of use) arising out of or related to the use, inability to use, performance or failure of this Service or any materials posted therein, or any information contained therein or stored or maintained by us, irrespective of whether such damages were foreseeable or arise in contract, tort, equity, restitution, by statute, at common law or otherwise.<br />
                                </p>
                                <p>
                                    <b>4.3 Third Party Service Providers. </b><br />
                                    You acknowledge and agree that your access and use of the Service is dependent on third party service providers such as internet, network, connectivity or other link providers. Your payment for any transactions or contracts concluded by you for the supply of goods or services formed through the Service is processed by third party payment system providers and we do not retain or process any such payment information. We cannot guarantee the security of such third party payment system(s) or any payment data on the Service. We are not responsible for any acts or omissions of any third parties and disclaim any and all liability in connection with the acts, omissions or defaults of such third parties. Without prejudice to the generality of the foregoing, we are not responsible for the results of any credit inquiry, the availability or performance of the Internet, your connection to the Internet or the actions or inaction of any other person or entity, including any internet service provider. By using and/or accessing the Service, you expressly relieve us from any and all liability in connection with the acts, omissions or defaults of such third parties.
                                </p>
                                <p>
                                    <b>4.4 Liability Implied by Law.</b><br />
                                    For the avoidance of doubt, nothing in this Condition 4 excludes, restricts or modifies any condition, warranty, right or liability implied into these Conditions where to do so is illegal or would render any provision hereof void.
                                </p>
                                <strong>5. INDEMNITY</strong>
                                <p>
                                    By using and/or accessing the Service, you agree to indemnify, hold harmless and defend us and our partners, affiliates, subsidiaries, advertising agencies, agents and their employees, officers, directors, and representatives from any claims, losses, damages, liabilities, costs and/or expenses, including but not limited to attorneys' fees, arising directly or indirectly from your access of and/or use of this Service, including without limitation, any claims of infringement of a third party's rights, any assertion and enforcement of our rights under these Conditions and any breaches of your obligations under these Conditions.
                                </p>
                                <strong>6. INTELLECTUAL PROPERTY RIGHTS</strong>
                                <p>
                                    <b>6.1 Ownership.</b><br />
                                    The intellectual property rights in and to the content on the Service, and in the material published on it are owned, licensed to or controlled by us, our licensors or our service providers. We reserve the right to enforce these intellectual property rights to the fullest extent of the law.<br />
                                </p>
                                <p>
                                    <b>6.2 Restricted Use.</b><br />
                                    You may not copy, reproduce, modify, republish, upload, post, transmit, adapt, download, distribute or howsoever deal with any content or material from the Service in any form or by any means without our prior written permission, and you are solely responsible for obtaining such permission before dealing with any content or material that is available on the Service.<br />
                                </p>
                                <strong>7. GENERAL</strong>
                                <p>
                                    <b>7.1 Amendments.</b> <br />
                                    We may at any time in our sole discretion amend or vary any provision of these Conditions by publication on the Service. If you do not consent to such variation or amendment, you should not use or access the Service. If, following such amendment or variation, you continue to use or access the Service, you shall be deemed to have irrevocably consented to such amendment or variation and to have agreed to be bound thereby.<br />
                                </p>
                                <p>
                                    <b>7.2 Correction of Errors.</b><br />
                                    There may be information on the Service that contains typographical errors, inaccuracies, or omissions that may relate to product descriptions, pricing, promotions, offers, and availability. We reserve the right to correct any errors, inaccuracies or omissions and to change or update information if any information on the Service is inaccurate at any time without prior notice.<br />
                                </p>
                                <p>
                                    <b>7.3 Illegality and Severability.</b><br />
                                    Each of the provisions of these Conditions is severable from the other(s). If any such provision or part thereof is or becomes invalid, unenforceable or illegal in any respect, such provision or part thereof shall, to the extent that such term is invalid, be deemed not to form part of these Conditions but the validity, enforceability or legality of the remaining provisions hereunder shall not in any way be affected or impaired thereby.<br />
                                </p>
                                <p>
                                    <b>7.4 Governing Law.</b><br />
                                    These Conditions shall be governed by, and construed in accordance with, the laws of Singapore, and you hereby submit to the exclusive jurisdiction of the courts of Singapore.<br />
                                </p>
                                <p>
                                    <b>7.5	Personal Data.</b> <br />
                                    By using and/or accessing the Service, you acknowledge that you have read and agree to our Privacy Policy at <a href='https://www.hci.edu.sg/about/personal-data-privacy-policy'>https://www.hci.edu.sg/about/personal-data-privacy-policy</a>, which forms a part of these Conditions, and you consent to our collection, use and/or disclosure or handling of your Personal Data for the purposes set out in the Privacy Policy.
                                </p>
                            </div>

                            <div className='popup-buttons'>
                                <button
                                    className='agree-btn'
                                    onClick={() => {
                                        // props.handleClosePopup();
                                        // props.setTermsTrue();
                                        props.sendPhotoToEmail();
                                        // document.querySelector('input[type="checkbox"]').checked = true;
                                    }}
                                >
                                    I Agree
                                </button>
                                <button
                                    className='decline-btn'
                                    onClick={() => {
                                        props.handleClosePopup();
                                        // props.setTermsFalse();
                                        // document.querySelector('input[type="checkbox"]').checked = false;
                                    }}
                                >
                                    Decline
                                </button>
                            </div>
                        </div>
                    </div>
                }
                <div className='buttons-wrapper'>
                    <button className='button left' onClick={props.backToDraw}>
                        <Ons.Icon icon='fa-chevron-left' style={{ color: '#000', fontSize: "1.5rem" }} /> BACK
                    </button>
                    <button className='button right' onClick={props.handleSubmitBtn}>
                        SUBMIT <Ons.Icon icon='fa-chevron-right' style={{ color: '#FEF4DD', fontSize: "1.5rem" }} />
                    </button>
                    {/* {props.termsAgreed ? <button className='button right' onClick={props.submitPhoto}>
                        SUBMIT <Ons.Icon icon='fa-chevron-right' style={{ color: '#FEF4DD', fontSize: "1.5rem" }} />
                    </button> : <button className='button right disabled' onClick={props.submitPhoto}>
                        SUBMIT <Ons.Icon icon='fa-chevron-right' style={{ color: '#666666', fontSize: "1.5rem" }} />
                    </button>} */}

                </div>
            </div>
        </>
    )
}

export default SubmitPhoto