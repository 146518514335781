import React, { useState, useEffect, useRef } from 'react';
import Logo from '../images/logo.png';
import axios from 'axios';
import { Buffer } from 'buffer';
import Swal from 'sweetalert2';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import * as Ons from 'react-onsenui';
import MainPage from './MainPage';
import PhotoPage from './PhotoPage';
// import CanvasDraw from './CanvasDraw';
import SketchingTools from './SketchingTools';
import SubmitPhoto from './SubmitPhoto';
import PrintPage from './PrintPage';
import FormButtons from './FormButtons';
import Audio from './Audio';
import CanvasDrawComponent from './CanvasDraw';
import Frame from '../images/newwow.png';

const TakeSelfie = () => {
    const videoRef = useRef(null);
    const photoRef = useRef(null);
    const audioRef = useRef(null);
    const timerRef = useRef(null);
    const startRef = useRef(null);
    const overlayRef = useRef(null);
    const imageRef = useRef(null);
    const sketchContainerRef = useRef(null);
    const canvasSketchRef = useRef(null);
    const [hasPhoto, setHasPhoto] = useState(false);
    const [hasSketchPhoto, setHasSketchPhoto] = useState(false);
    const [nextStep, setNextStep] = useState(false);
    const [base64Image, setBase64Image] = useState('');
    const [name, setName] = useState('');
    const [wellWishes, setWellWishes] = useState('');
    const [email, setEmail] = useState('');
    const [filename, setFilename] = useState('');
    const [counter, setCounter] = useState(3);
    const [isRunning, setIsRunning] = useState(false);
    const [printButton, setPrintButton] = useState(false);
    const [getSettings, setGetSettings] = useState('');
    const [termsAgreed, setTermsAgreed] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [isPrinted, setIsPrinted] = useState(false);
    const [data, setData] = useState('');
    const [currentTool, setCurrentTool] = useState("pen");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isStart, setIsStart] = useState(false);
    const [drawingScreen, setDrawingScreen] = useState(false);
    const [color, setColor] = useState('#ED1C24');
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [frame, setFrame] = useState(false);

    //handle the camera video
    const getVideo = () => {
        navigator.mediaDevices
            .getUserMedia({
                video: window.innerWidth <= 800 ? { width: 576, height: 972 } : { width: 972, height: 576 }
                // video: { width: 972, height: 576 }
            })
            .then(stream => {
                let video = videoRef.current;
                video.srcObject = stream;
                video.play();
            })
            .catch(err => {
                console.error("error:", err);
            })
    }
    useEffect(() => {
        getVideo();
    }, [videoRef])


    //handle take photo
    const takePhoto = () => {
        let video = videoRef.current;
        let photo = photoRef.current;
        const overlay = overlayRef.current;
        let ctx = photo.getContext('2d');
        ctx.imageSmoothingEnabled = true;
        const width = 972;
        const height = 576;
        photo.width = width;
        photo.height = height;
        ctx.drawImage(video, 0, 0, width, height);
        ctx.drawImage(overlay, 0, 0, width, height);
        setHasPhoto(true);

        const image64 = photo.toDataURL("image/png", 1.0);
        setBase64Image(image64);
    };



    //handle retake photo
    const closePhoto = () => {
        let photo = photoRef.current;
        let ctx = photo.getContext('2d');
        ctx.clearRect(0, 0, photo.width, photo.height);
        setHasPhoto(false);
        resetTimer();
    }

    //handle start drawing
    function startDrawing() {
        setDrawingScreen(true);
        console.log("draw");
        document.getElementById('screen-container').style.display = "none";
    }

    const token = 'Qs7t1Vi7apm0iXQZ6X99IHGMCdzQQlD0';

    //handle submit photo along with name and wellWishes + show print btn in nextScreen
    const submitPhoto = () => {
        // if (termsAgreed) {
        // console.log("print & submit")
        setShowPopup(true);
        // setNextStep(true);
        // document.getElementById('drawing-container').style.display = "none";
        // if (getSettings.enablePrinting === true) {
        //     setPrintButton(true);
        // } else {
        //     setPrintButton(false);
        // }
        let headers = {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ' + ${token}`,
            },
        };
        const form = new FormData();
        form.append('name', name)
        form.append('wellWishes', wellWishes)
        const buff = Buffer.from(data.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        const blob = new Blob([buff], { type: 'image/png' });
        form.append('photo', blob, 'camera.png');

        const url = 'https://hwachong.gallery/post/upload';
        axios.post(url, form, headers).then((res) => {
            setFilename(res.data.data.filename)
            console.log('post success: ', res)
        }).catch(err => {
            console.log('err: ', err)
        })

        // } else {
        // Display error
        //     Swal.fire(
        //         'Sorry',
        //         'Please Agree with Terms & Conditions.',
        //         'error'
        //     )
        // }
    }

    //handleSubmit photo to email without popup + agree to terms + next page
    // const sendPhotoToEmail = () => {
    //     setNextStep(true);
    //     document.getElementById('drawing-container').style.display = "none";
    //     setTermsAgreed(true)
    //     axios({
    //         method: 'post',
    //         url: 'https://hwachong.gallery/post/send',
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //             'Content-Type': 'application/json',
    //         },
    //         data: JSON.stringify({ email, filename })
    //     }).then((res) => {
    //         console.log(JSON.stringify(res.data));
    //     }).catch(err => {
    //         console.log('err: ', err)
    //     })
    // }

    //handleSubmit photo to email + show popup using swal
    const onSubmit = () => {
        if (!email) {
            Swal.fire({
                timer: 3500,
                didOpen: async () => {
                    Swal.showLoading()

                },
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.timer) {
                    console.log('close popup');
                    setNextStep(true);
                    document.getElementById('drawing-container').style.display = "none";
                    setTermsAgreed(true)
                }
            })
        } else {
            Swal.fire({
                timer: 3500,
                didOpen: async () => {
                    Swal.showLoading()
                    axios({
                        method: 'post',
                        url: 'https://hwachong.gallery/post/send',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        data: JSON.stringify({ email, filename })
                    })
                        .then(function (response) {
                            console.log(JSON.stringify(response.data));
                            setNextStep(true);
                            document.getElementById('drawing-container').style.display = "none";
                            setTermsAgreed(true)
                            // Swal.fire({
                            //     position: 'center',
                            //     icon: 'success',
                            //     title: 'Thank You!',
                            //     text: response.data.data.msg,
                            //     showConfirmButton: false,
                            //     timer: 2500
                            // })
                            // setEmail('');
                            // setIsSubmitted(true)
                        })
                        .catch(function (error) {
                            console.log(error);
                            // Swal.fire({
                            //     position: 'center',
                            //     icon: 'error',
                            //     title: "Error!",
                            //     text: error,
                            //     showConfirmButton: false,
                            //     timer: 2500
                            // })
                        });
                },
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.timer) {
                    console.log('close popup');
                }
            })
        }
    }

    //start and reset countdown timer 
    function startTimer() {
        document.getElementById('timer').style.display = "block";
        setCounter(3);
        setIsRunning(true);
        timerRef.current.play();
    }

    function resetTimer() {
        document.getElementById('timer').style.display = "none";
        setCounter(3);
        setIsRunning(false);
    }

    useEffect(() => {
        let interval;
        if (isRunning) {
            interval = setInterval(() => {
                timerRef.current.play();
                setCounter(counter - 1);
                if (counter === 1) {
                    document.getElementById('timer').style.display = "none";
                    takePhoto();
                    setIsRunning(false);
                    audioRef.current.play();
                }
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [counter, isRunning]);


    useEffect(() => {
        let headers = {
            headers: {
                'Authorization': `Bearer ' + ${token}`
            },
        };
        const url = 'https://hwachong.gallery/admin/settings/list';
        axios.get(url, headers).then((res) => {
            setGetSettings(res.data);
            console.log(res.data)
        }).catch(err => {
            console.log('err: ', err);
        })
    }, [])

    //handle print photo 
    function handlePrint() {
        const buff = Buffer.from(data.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        const blob = new Blob([buff], { type: 'image/png' });
        const file = new File([blob], 'frame.png', { type: 'image/png' });
        const formData = new FormData();
        formData.append('photo', file);
        setIsPrinted(true);
        let timerInterval
        Swal.fire({
            title: 'Please wait your photo is being printed.',
            html: '<p>I will close in <b></b> milliseconds.</p>',
            timer: 3000,
            timerProgressBar: true,
            didOpen: async () => {
                Swal.showLoading()
                const b = Swal.getHtmlContainer().querySelector('b')
                document.querySelector('p').style.display = "none";
                timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft()
                }, 500)
                axios({
                    method: 'post',
                    url: 'https://junaid.frp.t05.sg/printhtml',
                    data: formData,
                })
                    .then(function (res) {
                        console.log(res);
                        if (res.data.success === true) {
                            clearInterval(timerInterval)
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Thank You!',
                                text: "Success! Please collect your photo.",
                                showConfirmButton: false,
                                timer: 2500
                            })
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            willClose: () => {
                clearInterval(timerInterval)
            }
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('close popup')
            }
        })


    }


    //sketching tools
    const clearSketch = () => {
        canvasSketchRef.current.clearCanvas();
        // canvasSketchRef.current.clear();
    }
    const handleToolChange = (tool) => {
        setCurrentTool(tool);
        console.log(tool)
        if (tool === "pen") {
            canvasSketchRef.current.eraseMode(false);
        } else if (tool === "eraser") {
            canvasSketchRef.current.eraseMode(true);
        }
    };
    const undoSketch = () => {
        canvasSketchRef.current.undo();
    }
    const redoSketch = () => {
        canvasSketchRef.current.redo();
    }

    //save and get sketch/drawing photo url
    const getDataUrlSketch = () => {
        setHasSketchPhoto(true);
        setTermsAgreed(false);
        setName('');
        // setWellWishes('');
        console.log("form");
        canvasSketchRef.current.exportImage('image/png', 1.0)
            .then(data => {
                // console.log(data);
                setData(data)
            })
            .catch(e => {
                console.log(e);
            });

    }


    //handle close, start and back buttons
    function handleClose() {
        window.location.reload(true);
    }

    function startApp() {
        startRef.current.play()
        console.log("start");
        setIsStart(true);
        document.getElementById('video').style.display = "block";
    }

    function backToPhoto() {
        setDrawingScreen(false);
        document.getElementById('screen-container').style.display = "flex";
    }
    function backToDraw() {
        setHasSketchPhoto(false);
        setWellWishes('');
    }
    function backToForm() {
        setNextStep(false);
        document.getElementById('drawing-container').style.display = "flex";
        setHasSketchPhoto(true);
        setTermsAgreed(false)
        document.querySelector('input[type="checkbox"]').checked = false;
    }

    //handle colorpicker sketching tools
    const handleDisplayColor = () => {
        setDisplayColorPicker(true);
    }
    // const handleCloseColor = () => {
    //     setDisplayColorPicker(false);
    // }

    const handleColorChange = (newColor) => {
        setColor(newColor.hex);
    };
    const colorPalette = [
        "#000000",
        '#FFFFFF',
        "#ED1C24",
        '#fc6',
    ];

    //handle selfie optional
    const handleOptions = () => {
        setIsVisible(true);
    }

    function startDraw() {
        setDrawingScreen(true);
        console.log("draw");
        document.getElementById('screen-container').style.display = "none";
        let photo = photoRef.current;
        const overlay = overlayRef.current;
        let ctx = photo.getContext('2d');
        ctx.imageSmoothingEnabled = true;
        const width = 972;
        const height = 576;
        photo.width = width;
        photo.height = height;
        ctx.drawImage(overlay, 0, 0, width, height);
        setHasPhoto(true);

        const image64 = photo.toDataURL("image/png", 1.0);
        setBase64Image(image64);
        
        //setFrame(true)
    }
    function handleClosePopup() {
        setShowPopup(false);
    }



    return (
        <Ons.Page> 
            <div id='screen-container' className={'screen-container ' + (isStart ? 'isStart' : '')}>
                <div className='camera'>
                    {isStart &&
                        <>
                            <div style={{ width: '25rem' }}>
                                <img src={Logo} alt='hwa chong logo' className='logo-img' />
                            </div>
                            <div>
                                <h1 className='screen-text'>Take a Selfie with your friends!</h1>
                            </div>
                        </>
                    }
                    {!isStart && (
                        <MainPage isVisible={isVisible} startApp={startApp} startDraw={startDraw} onClickOutside={() => { setIsVisible(false) }} />
                    )}
                    <div id="timer">{counter}</div>
                    <div className='video-wrapper'>
                        <video ref={videoRef} id="video" />
                    </div>

                    {isStart ?
                        <button className='round-button' onClick={startTimer}>
                            <Ons.Icon icon='fa-camera' style={{ color: '#FEF4DD', fontSize: "2.5rem" }} />
                        </button>
                        : <button className='main-start-button' onClick={handleOptions}>TAP TO START</button>}
                    <Audio audioRef={audioRef} timerRef={timerRef} startRef={startRef} />
                </div>
                <PhotoPage hasPhoto={hasPhoto} photoRef={photoRef} base64Image={base64Image} imageRef={imageRef} overlayRef={overlayRef} closePhoto={closePhoto} startDrawing={startDrawing} />
            </div>
            {drawingScreen && (<div id='drawing-container'>
                <a href='/' style={{ width: '25rem' }}><img src={Logo} alt='hwa chong logo' className='logo-img' /></a>
                {!hasSketchPhoto &&
                    <>
                        <div>
                            <h1 className='screen-text'>Start drawing on your photo!</h1>
                        </div>
                        <CanvasDrawComponent canvasSketchRef={canvasSketchRef} frame={frame} base64Image={base64Image} color={color} />
                        <SketchingTools handleDisplayColor={handleDisplayColor} displayColorPicker={displayColorPicker} colorPalette={colorPalette} handleColorChange={handleColorChange} handleToolChange={handleToolChange} undoSketch={undoSketch} redoSketch={redoSketch} clearSketch={clearSketch} onClickOutside={() => { setDisplayColorPicker(false) }} />
                        <input type="text" placeholder='Enter your well wishes' value={wellWishes} onChange={(e) => setWellWishes(e.target.value)} />
                        <FormButtons frame={frame} handleClose={handleClose} getDataUrlSketch={getDataUrlSketch} backToPhoto={backToPhoto} />
                    </>
                }
                {hasSketchPhoto &&
                    <SubmitPhoto data={data} name={name} setName={setName} email={email} setEmail={setEmail} termsAgreed={termsAgreed} showPopup={showPopup} setShowPopup={setShowPopup} backToDraw={backToDraw} handleClosePopup={handleClosePopup} handleSubmitBtn={submitPhoto} sendPhotoToEmail={onSubmit} base64Image={base64Image} />
                }
            </div>)}
            {nextStep &&
                <PrintPage data={data} printButton={printButton} isPrinted={isPrinted} handleClose={handleClose} isSubmitted={isSubmitted} backToForm={backToForm} handlePrint={handlePrint} />
            }
        </Ons.Page>
    )
}

export default TakeSelfie;