import React, { useEffect, useRef } from 'react';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import * as Ons from 'react-onsenui';
import { CirclePicker } from 'react-color';

const SketchingTools = (props) => {
    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);
    return (
        <div className='sketching-tools'>
            <button className='icon-button' onClick={props.handleDisplayColor} style={{ marginRight: "1rem" }}><Ons.Icon icon='fa-palette' style={{ color: '#6e1919', fontSize: "2rem" }} /></button>
            <div ref={ref} style={{ position: 'absolute', zIndex: '2', top: "7rem", left: "4rem", padding: '1rem', borderRadius: '1rem' }}>
                {/* <div style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px', }} /> */}
                {props.displayColorPicker &&
                    <CirclePicker colors={props.colorPalette} onChange={props.handleColorChange} />
                }

            </div>
            <button className='icon-button' onClick={() => props.handleToolChange("pen")} style={{ marginRight: "1rem" }}>
                <Ons.Icon icon='fa-pen' style={{ color: '#6e1919', fontSize: "2rem" }} />
            </button>
            <button className='icon-button' onClick={() => props.handleToolChange("eraser")} style={{ marginRight: "1rem" }}>
                <Ons.Icon icon='fa-eraser' style={{ color: '#6e1919', fontSize: "2rem" }} />
            </button>
            <button className='icon-button' onClick={props.undoSketch} style={{ marginRight: "1rem" }}>
                <Ons.Icon icon='fa-rotate-left' style={{ color: '#6e1919', fontSize: "2rem" }} />
            </button>
            <button className='icon-button' onClick={props.redoSketch} style={{ marginRight: "1rem" }}>
                <Ons.Icon icon='fa-rotate-right' style={{ color: '#6e1919', fontSize: "2rem" }} />
            </button>
            <button className='icon-button' onClick={props.clearSketch} style={{ marginRight: "1rem" }}>
                <Ons.Icon icon='fa-trash' style={{ color: '#6e1919', fontSize: "2rem" }} />
            </button>
        </div >
    )
}

export default SketchingTools